<template>
	<div class="career-page-block">
		<section class="section-simple md light">
			<div class="career-page-start">
				<div class="content">
					<Breadcrumb :page-name="'Профориентация'" />
					<h1
						id="seo-title"
						class="seo-title"
					>
						Тест на определение профориентации
					</h1>
					<h2 class="page-title">
						Твоя возможность найти себя
					</h2>
					<div
						v-if="!isLoaded"
						class="preloader"
						style="margin-top: 200px;"
					/>
					<template v-else>
						<div class="career-page-video-group">
							<div class="grid">
								<div
									v-for="video in viewedVideos"
									:key="video.id"
									class="box"
								>
									<div
										class="career-page-video-item"
										@click.stop="modalOpen('video_', {
											id: video.id,
											src: video.file,
											poster: video.image_video[0].image
										})"
									>
										<div
											class="career-page-video-pic"
											:style="video.image_video[0].image | bgrDynamicImageCover"
										/>
										<h3 class="career-page-video-title">
											{{ video.name }}
										</h3>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="sortedVideos.length > 8 && sortedVideos.length > viewedVideos.length"
							class="career-page-button-block"
						>
							<div
								class="button orange md"
								@click="pageVideos++"
							>
								Больше профессий
							</div>
						</div>
					</template>
				</div>
			</div>
		</section>

		<section class="section-simple md">
			<div class="content">
				<div class="career-page-panel">
					<div class="panel-shadow">
						<div class="bar-block">
							<div class="bar-box">
								<h2 class="section-title">
									Узнай к чему у тебя таланты <br>и начни работать
								</h2>
							</div>
							<div class="bar-box">
								<div class="career-page-button-block">
									<div
										id="btnTakeProfTest"
										class="button blue md"
										@click="startTest"
									>
										Пройти тест
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="career-page-feature-group">
					<div class="grid">
						<div class="box">
							<div class="career-page-feature-item">
								<div class="career-page-feature-pic">
									<img
										:src="'/img/career/career-feature-pic-1.png' | prodSrcImage"
										alt="Тест за 7 минут покажет к каким профессиям у тебя предрасположенность"
									>
								</div>
								<h3 class="career-page-feature-title">
									Тест за 7 минут покажет к каким профессиям у тебя предрасположенность:
								</h3>
								<div class="text sm">
									<p>Мы предложим тебе ответить на 3 простых вопроса о сфере твоих интересов и личных качествах. Отвечай внимательно.</p>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="career-page-feature-item">
								<div class="career-page-feature-pic">
									<img
										:src="'/img/career/career-feature-pic-2.png' | prodSrcImage"
										alt="Узнай, какие еще профессии тебе подходят"
									>
								</div>
								<h3 class="career-page-feature-title">
									Узнай, какие еще профессии тебе подходят:
								</h3>
								<div class="text sm">
									<p>По результатам теста мы покажем тебе наиболее подходящие и близкие профессии. После теста обязательно загляни в раздел с вакансиями и стажировками  - возможно там уже ждет твоя первая работа.</p>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="career-page-feature-item">
								<div class="career-page-feature-pic">
									<img
										:src="'/img/career/career-feature-pic-3.png' | prodSrcImage"
										alt="А какие профессии тебе не подходят"
									>
								</div>
								<h3 class="career-page-feature-title">
									А какие профессии тебе не подходят:
								</h3>
								<div class="text sm">
									<p>Еще мы покажем, в каких профессиях тебе скорее всего будет неинтересно. Людям свойственно менять интересы, не забудь пройти тест повторно через некоторое время.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple close light">
			<div class="career-page-footer">
				<div class="content">
					<div class="bar-block">
						<div class="bar-box">
							<h2 class="section-title">
								Пора узнать кем быть
							</h2>
						</div>
						<div class="bar-box">
							<div class="career-page-button-block">
								<div
									id="btnTakeProfTest"
									class="button blue md"
									@click="startTest"
								>
									Пройти тест
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb';

export default {
    components: {
        Breadcrumb
    },
    data: () => ({
        iframeUrl: '',
        isLoaded: false,
        pageVideos: 1,
        userSignIn: {
            email: '',
            pass: ''
        }
    }),
    computed: {
        ...mapGetters('user', {
            user: 'userInfo',
            isLogged: 'isLoggedIn'
        }),
        ...mapGetters('tb', {
            videos: 'getAllVideos'
        }),
        viewedVideos() {
            return this.sortedVideos && this.sortedVideos.slice(0, this.pageVideos * 8)
        },
        isResidueVideos() {
            return this.videos && (this.videos.length - this.pageVideos * 8 > 0)
        },
        sortedVideos() {
            return this.videos && this.videos.filter(video => video.id !== 7 )
        }
    },
    created() {
        if (IS_DEV) {
            this.userSignIn.email = 'vishnyakov.d.a@mail.ru'
            this.userSignIn.pass = 'Lift1234'
        }

        if (this.videos) {
            this.isLoaded = true
        } else {
            this.$store.dispatch('tb/getAllVideos', { slider: true, ordering: 'priority' }).finally(() => this.isLoaded = true)
        }
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'));
    },
    methods: {
        startTest() {
            if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru')) {
                this.$metrika.reachGoal('profteststart')
            }
            if (this.isLogged) {
                this.$store.dispatch('user/career', this.user.id).then(url => this.modalOpen('testing', url))
            } else {
                this.modalOpen('testing', 'https://intalant.ru/lift/lift/index')
            }
        }
    }
}
</script>

<style lang="less">
.career-page-block{
    padding-bottom: 70px;

    @media @mobile-end{
        padding-bottom: 35px;
    }
    .page-title{
        margin-bottom: 45px;
    }
    .block-title{
        margin-bottom: 35px;
        font-size: 24px;
        @media @tablet-end{
            margin-bottom: 30px;
        }
        @media @mobile-end{
            font-size: 22px;
        }
    }
    .bar-block{
        .bar-box{
            padding: 0 20px;
            @media @mobile-end{
                padding: 0;
            }
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
            }
        }
    }
}

.career-page-start{
    padding-bottom: 55px;
}

.career-page-button-block{
    display: flex;
    align-items: center;

    @media @mobile-end{
        display: block;
        margin-bottom: 0;
    }
    .button{
        flex-shrink: 0;
        min-width: 295px;
        margin-right: 70px;
        .font-title;

        @media @tablet-end{
            margin-right: 50px;
        }
        @media @mobile-end{
            min-width: 100%;
        }
        &:last-child{
            margin-right: 0;
        }
    }
}
.career-page-button-title{
    font-size: 21px;

    @media @mobile-end{
        margin-top: 25px;
        font-size: 18px;
    }
}
.career-page-pic{
    display: block;
    max-width: 100%;
    margin-bottom: 55px;


    @media @tablet-end{
        margin-bottom: 50px;
    }

}

.career-page-video-group{
    padding-bottom: 15px;

    @media @mobile-end{
        padding-bottom: 10px;
    }
    .grid{
        margin: 0 -25px;

        @media @tablet-end{
            margin: 0 -15px;
        }
        @media @mobile-end{
            margin: 0 -8px;
        }
    }
    .box{
        width: 25%;
        padding: 0 25px;

        @media @tablet-end{
            width: 33.33%;
            padding: 0 15px;
        }
        @media @mobile-end{
            width: 50%;
            padding: 0 8px;
        }
        @media (max-width: 400px){
            width: 100%;
        }
    }
}
.career-page-video-item{
    margin-bottom: 35px;

    @media @mobile-end{
        margin-bottom: 30px;
    }
}
.career-page-video-pic{
    overflow: hidden;
    position: relative;
    display: block;
    height: 140px;
    margin-bottom: 12px;
    .cover;
    border-radius: 5px;
    cursor: pointer;
    .transition;
    &:before{
        opacity: 0.3;
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000469;
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        margin-top: -16px;
        margin-left: -16px;
        background: url(../assets/img/icons/icon-career-video-play-white.svg) center center no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
    }
    &:hover{
        opacity: 0.9;
    }
}
.career-page-video-title{
    .font;
    font-size: 21px;
    font-weight: 400;
    cursor: pointer;
    .transition;

    @media @mobile-end{
        font-size: 19px;
    }
    &:hover{
        color: @color-main;
    }
}

.career-page-panel{
    margin: -125px 0 55px;

    @media @mobile-end{
        margin-bottom: 50px;
    }
    .panel-shadow{
        margin: 0 -50px;
        padding: 45px 50px;
        .button{
            margin-bottom: 0;
        }
    }
    .section-title{
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.5;
        font-size: 36px;

        @media @tablet-end{
            line-height: 1.35;
            font-size: 32px;
        }
        @media @mobile-end{
            font-size: 28px;
        }
        br{
            @media @tablet-end{
                display: none;
            }
        }
    }
    .bar-block{
        align-items: center;
        margin-bottom: 0;
    }
    .career-page-button-block{
        @media @mobile-end{
            margin-top: 25px;
        }
    }
}

.career-page-feature-group{
    margin-bottom: -40px;
    .grid{
        margin: 0 -35px;
    }
    .box{
        width: 33.33%;
        padding: 0 35px;
        @media @tablet-end{
            width: 50%;
        }
        @media @mobile-end{
            width: 100%;
        }
    }
}
.career-page-feature-item{
    margin-bottom: 40px;
    text-align: center;

    @media @mobile-end{
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
}
.career-page-feature-pic{
    height: 220px;
    line-height: 220px;
    margin-bottom: 20px;
    img{
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        max-height: 220px;
    }
}
.career-page-feature-title{
    min-height: 70px;
    margin-bottom: 25px;
    .font-title;
    font-size: 18px;
    font-weight: 700;


    @media @tablet-end{
        min-height: 0;
        margin-bottom: 20px;
    }
}

.career-page-footer{
    padding: 30px 0;
    .bar-block{
        align-items: center;
    }
    .section-title{
        margin-top: 0;
        margin-bottom: 0;
        font-size: 24px;
        @media @mobile-end{
            font-size: 22px;
        }
    }
    .career-page-button-block{
        @media @mobile-end{
            margin-top: 25px;
        }
    }
}
</style>
